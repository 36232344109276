import { NavigationFailure } from "vue-router";
import { currentRoute, router } from "@/core/router";

export function changeLocale(locale: string): Promise<void | NavigationFailure> {
  return router.replace({
    name: currentRoute.value.name,
    params: {
      locale,
    },
  });
}
